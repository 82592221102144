<template>
  <div class="MembersTable responsive-table">
    <table class="table is-full has-cells-vertically-centered">
      <thead class="has-background-grey-lighter is-size-6 no-hover">
        <tr>
          <th>{{ $t('general.user') }}</th>
          <th class="has-text-left">
            {{ $t('forms.email_no_dash') }}
          </th>
          <th>
            {{ $t('forms.role') }}
          </th>
          <th>{{ $t('general.actions') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="member in members"
          :key="member.id"
          v-loading="updatingUserRole === member.id"
          data-testid="MembersTable__row"
        >
          <td class="p-v-m">
            <div class="is-flex is-aligned-middle">
              <round-avatar
                :src="member.avatar.cropped"
                :acronym-fallback="member.first_name"
                size="small"
              />
              <div class="p-l-s">
                <div v-if="!member.full_name" class="is-italic has-text-grey">
                  {{ $t('components.members_table.no_name_set') }}
                </div>
                <template v-else>
                  {{ member.full_name }}
                </template>
              </div>
            </div>
          </td>
          <td class="p-v-m has-text-left is-width-1/4">
            {{ member.email }}
          </td>
          <td class="p-v-m">
            <div class="is-flex is-aligned-middle">
              <form-field class="m-b-none">
                <basic-select
                  :values="roles"
                  :value="member.role_id"
                  :disabled="member.id === currentUser.id"
                  label-prop="name"
                  value-prop="id"
                  key-prop="id"
                  data-testid="changeRoleDropdown"
                  @change="assignRole(member, $event)"
                />
              </form-field>

              <router-link
                :to="{ name: 'settings.user.permissions', params: { user_id: member.id }}"
                class="button is-text is-hidden"
              >
                <v-icon type="pencil" />
              </router-link>
            </div>
          </td>
          <td class="is-narrow">
            <v-button
              v-if="!isCurrentUser(member)"
              :loading="isRemovingMember === member.id"
              :title="$t('forms.remove_user')"
              class="is-dark is-outlined is-small"
              data-testid="removeUserButton"
              @click="removeUser(member.id)"
            >
              {{ $t('forms.remove_user') }}
            </v-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BrandApiService } from '@hypefactors/shared/js/services/api/BrandApiService'

export default {
  props: {
    members: {
      type: Array,
      default: () => ([])
    },
    brandId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      isRemovingMember: null,
      assignedRole: 1,
      roles: [],
      updatingUserRole: null
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  mounted () {
    this.fetchRoles()
  },

  methods: {
    isCurrentUser (member) {
      return this.currentUser.id === member.id
    },

    async removeUser (userId) {
      if (this.members.findIndex(m => m.id === userId) === -1) {
        return
      }

      try {
        await this.$confirm(this.$t('components.brand_management.confirm_remove_user'))

        this.isRemovingMember = userId

        await BrandApiService.removeUser(this.brandId, userId)

        const members = this.members.slice(0).filter(m => m.id !== userId)

        this.$notify.success(this.$t('components.members_table.member_removed'))

        this.$emit('update:members', members)
      } catch (err) {
        this.$displayRequestError(err)
      } finally {
        this.isRemovingMember = null
      }
    },

    async fetchRoles () {
      try {
        this.roles = await this.$api.getData('roles?type=brand')
      } catch (err) {
        this.$displayRequestError(err)
      }
    },

    async assignRole (member, role) {
      const to = this.roles.find(r => r.id === role).name

      const from = this.roles.find(r => r.id === member.role_id).name

      const memberName = member.full_name.trim() || this.$t('components.members_table.this_member')

      try {
        await this.$confirm(this.$t('components.members_table.confirm_role_change', { member: memberName, from, to }))

        this.updatingUserRole = member.id

        await BrandApiService.updateMemberRole(this.brandId, member.id, { role })

        const memberId = this.members.findIndex(m => m.id === member.id)

        const members = this.members.slice(0)

        members[memberId] = {
          ...member,
          role_id: role
        }

        this.$notify.success(this.$t('components.members_table.role_changed_success'))

        this.$emit('update:members', members)
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.updatingUserRole = null
      }
    }
  }
}
</script>
